import { useEffect, useState } from 'react'

export const useIsHovering = (
  ref: React.RefObject<HTMLElement> | null,
): boolean => {
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    if (!ref) return

    const checkHover = (e: MouseEvent): void => {
      if (ref.current) {
        const mouseOver = ref.current.contains(e.target as HTMLElement)
        if (!isHovering && mouseOver) {
          setIsHovering(true)
        }

        if (isHovering && !mouseOver) {
          setIsHovering(false)
        }
      }
    }

    window.addEventListener('mousemove', checkHover)

    return () => {
      window.removeEventListener('mousemove', checkHover)
    }
  }, [isHovering, ref])
  return isHovering
}
